<template>
  <div class="main">
    <van-icon
      name="warning"
      size="50px"
      style="margin-bottom: 20px"
    />
    <div>页面出错了</div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="less" scoped>
.main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 400px;
}
</style>
